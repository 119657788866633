<template>
  <el-dialog
    :title="$t('enrollment.detail.contract')"
    :visible.sync="isContractDialogVisible"
    :before-close="
      () => {
        $emit('toggleContractDialog', false);
      }
    "
    center
  >
    <template slot="title">
      <h5>
        {{ $t("enrollment.detail.contract") }}
      </h5>
    </template>
    <el-tabs v-model="actionType">
      <el-tab-pane
        v-if="hasContract || hasSignedContract"
        label="查看合約"
        name="viewContract"
      >
        <h5>合約</h5>
        <div>
          <a v-if="hasContract" :href="contractUrl" target="_blank">
            查看合約內容 <i class="fas fa-eye" />
          </a>
          <span v-else>當前暫無合約</span>
        </div>
        <hr class="separate-line" />
        <h5>已簽名合約</h5>
        <div>
          <a v-if="hasSignedContract" :href="signedContractUrl"
            >查看已簽名合約內容 <i class="fas fa-eye"
          /></a>
          <span v-else>當前暫無已簽名合約</span>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="`${hasContract ? '重新' : ''}發送合約`"
        name="sendContract"
      >
        <div v-loading="fileLoading" element-loading-text="Uploading">
          <h4>合約</h4>
          <div style="margin: 4px 0px;">
            <template v-if="contractUrl">
              <a :href="contractUrl" target="_blank">
                查看合約內容 <i class="fas fa-eye action-icon" />
              </a>
              <el-button
                v-if="!displayUploadContract"
                @click="displayUploadContract = true"
                size="mini"
                type="primary"
              >
                編輯已上傳合約
              </el-button>
              <el-button
                v-if="displayUploadContract"
                @click="displayUploadContract = false"
                size="mini"
                type="danger"
              >
                取消編輯
              </el-button>
            </template>
            <span v-else>當前暫無合約</span>
          </div>
          <el-upload
            v-if="!contractUrl || displayUploadContract"
            drag
            :action="postUrl"
            :headers="token"
            :data="{ type: 'contract' }"
            name="document"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <div>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                將文件拖到此處，或<em>點擊上傳</em>
              </div>
            </div>
          </el-upload>
          <span style="color: red; font-size: 12px">
            * 上傳合約後記得點選「發送合約」儲存
          </span>
          <hr class="separate-line" />
          <h4>信件語言</h4>
          <el-select v-model="lang">
            <el-option label="繁體中文" value="tw" />
            <el-option label="简体中文" value="cn" />
            <el-option label="English" value="en" />
          </el-select>
          <hr class="separate-line" />
          <h4>寄送對象</h4>
          <div v-if="enrolled_session.student_user_id && user">
            <h5>學生</h5>
            <el-checkbox v-model="sendToStudent">
              {{
                `${userMethods.displayName(
                  user.basic_info.first_name,
                  user.basic_info.last_name,
                  user.basic_info.chinese_name
                )} - ${user.basic_info.email}`
              }}
            </el-checkbox>
            <router-link
              :to="{
                name: 'ProfileForAdmin',
                params: { id: user.basic_info.id }
              }"
            >
              <i class="fas fa-edit action-icon" />
            </router-link>
            <template v-if="user.student && user.student.parents">
              <h5>家長</h5>
              <el-checkbox-group
                v-model="sendToParentEmails"
              >
                <div :key="parent.email" v-for="parent in user.student.parents">
                  <el-checkbox :label="parent.email">
                    {{
                      `${userMethods.displayName(
                        parent.first_name,
                        parent.last_name
                      )} - ${parent.email}`
                    }}
                    <router-link
                      :to="{
                        name: 'ProfileForAdmin',
                        params: { id: parent.pivot.parent_user_id }
                      }"
                    >
                      <i class="fas fa-edit action-icon" />
                    </router-link>
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </template>
          </div>
          <div v-else>
            <h5>聯絡人</h5>
            <el-checkbox v-model="sendToContactPerson">
              {{
                `${userMethods.displayName(
                  enrolled_session.first_name,
                  enrolled_session.last_name
                )} - ${enrolled_session.email}`
              }}
            </el-checkbox>
          </div>
          <div>
            <div style="display: flex;">
              <h5>其他寄送對象</h5>
            </div>
            <el-input
              v-model="otherEmails"
              placeholder="如要寄送多個 Emails，每個 Email 之間請用逗號分隔。如：mail1@gmail.com,mail2@gmail.com"
            />
          </div>
          <div style="margin: 8px 0px; text-align: right">
            <el-button @click="$emit('toggleContractDialog', false)"
              >取消</el-button
            >
            <el-button @click="storeAndaSendContract" type="primary">{{
              `${hasContract ? "重新" : ""}發送合約`
            }}</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="上傳已簽名合約" name="uploadSignedContract">
        <div v-loading="fileLoading" element-loading-text="Uploading">
          <h4>已簽名合約</h4>
          <div style="margin: 4px 0px;">
            <template v-if="signedContractUrl">
              <a :href="signedContractUrl" target="_blank">
                查看已簽名合約內容 <i class="fas fa-eye action-icon" />
              </a>
              <el-button
                v-if="!displayUploadSignedContract"
                @click="displayUploadSignedContract = true"
                size="mini"
                type="primary"
              >
                編輯已上傳簽名合約
              </el-button>
              <el-button
                v-if="displayUploadSignedContract"
                @click="displayUploadSignedContract = false"
                size="mini"
                type="danger"
              >
                取消編輯
              </el-button>
            </template>
            <span v-else>當前暫無已簽名合約</span>
          </div>
          <el-upload
            v-if="!signedContractUrl || displayUploadSignedContract"
            drag
            :action="postUrl"
            :headers="token"
            :data="{ type: 'contract' }"
            name="document"
            :show-file-list="false"
            :on-success="handleSignedContractSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <div>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                將文件拖到此處，或<em>點擊上傳</em>
              </div>
            </div>
          </el-upload>
          <span style="color: red; font-size: 12px">
            * 上傳合約後記得點選「儲存」
          </span>
          <div style="text-align: right">
            <el-button @click="$emit('toggleContractDialog', false)">
              {{ $t("button.cancel") }}
            </el-button>
            <el-button @click="storeSignedContract" type="primary">
              {{ $t("button.save") }}
            </el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import JwtService from "@/common/jwt.service";
import enrollmentApi from "@/apis/enrollment";
import profileApi from "@/apis/profile";
import { user } from "@ivy-way/material";

export default {
  props: {
    enrolled_session: {
      type: Object,
      default: () => ({})
    },
    hasContract: {
      type: Boolean,
      default: false
    },
    hasSignedContract: {
      type: Boolean,
      default: false
    },
    isContractDialogVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String || Number,
      default: null
    }
  },
  computed: {
    userMethods() {
      return user;
    },
    token() {
      return { Authorization: `Bearer ${JwtService.getToken()}` };
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "document";
    }
  },
  data() {
    return {
      user: null,
      sendToStudent: false,
      sendToParentEmails: [],
      sendToContactPerson: false,
      fileLoading: false,
      actionType: "sendContract",
      contractUrl: "",
      signedContractUrl: "",
      otherEmails: "",
      lang: "tw",
      displayUploadContract: false,
      displayUploadSignedContract: false
    };
  },
  watch: {
    enrolled_session() {
      if (this.hasContract) {
        this.contractUrl = this.enrolled_session.contract_url;
      }

      if (this.hasSignedContract) {
        this.signedContractUrl = this.enrolled_session.signed_contract_url;
      }
    }
  },
  async created() {
    if (this.hasContract || this.hasSignedContract) {
      this.actionType = "viewContract";
    }

    if (this.enrolled_session.student_user_id) {
      const user = await profileApi.getUserData(
        this.enrolled_session.student_user_id
      );
      this.user = user;
    }

    if (this.hasContract) {
      this.contractUrl = this.enrolled_session.contract_url;
    }

    if (this.hasSignedContract) {
      this.signedContractUrl = this.enrolled_session.signed_contract_url;
    }
  },
  methods: {
    handleSignedContractSuccess(res) {
      this.signedContractUrl = res.document.url;
      this.fileLoading = false;
      this.displayUploadSignedContract = false;
    },
    handleAvatarSuccess(res) {
      this.contractUrl = res.document.url;
      this.fileLoading = false;
      this.displayUploadContract = false;
    },
    beforeAvatarUpload() {
      this.fileLoading = true;
      return true;
    },
    async storeAndaSendContract() {
      if (this.contractUrl === "") {
        this.$message.warning("請上傳合約！");
        return;
      }

      let emails = [];

      if (this.enrolled_session.student_user_id) {
        emails = [...this.sendToParentEmails];
        if (this.sendToStudent) {
          emails.push(this.user.basic_info.email);
        }
      } else {
        if (this.sendToContactPerson) {
          emails.push(this.enrolled_session.email);
        }
      }

      if (this.otherEmails !== "") {
        const otherEmails = this.otherEmails.split(",");
        emails = [...emails, ...otherEmails];
      }

      if (emails.length === 0) {
        this.$message.warning("請選擇收件人！");
        return;
      }

      try {
        await this.storeContract();
        await this.sendContract(emails);

        this.$message.success("儲存並發送成功！");
        this.$emit("toggleContractDialog", false);
        this.$emit("reload");
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async sendContract(emails) {
      await enrollmentApi.sendContractOfEnrollment({
        id: this.id,
        emails: emails,
        lang: this.lang
      });
    },
    async storeContract() {
      await enrollmentApi.storeContractOfEnrollment({
        id: this.id,
        contract_url: this.contractUrl
      });
    },
    async storeSignedContract() {
      if (this.signedContractUrl === "") {
        this.$message.warning("請上傳已簽名合約！");
        return;
      }
      try {
        await enrollmentApi.storeSignedContractOfEnrollment({
          id: this.id,
          signed_contract_url: this.signedContractUrl
        });
        this.$emit("toggleContractDialog", false);
        this.$emit("reload");
        this.$message.success("儲存成功！");
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
