<template>
  <el-dialog
    :title="$t('enrollment.detail.placementTest')"
    :visible.sync="isAssignTestDialogVisible"
    :before-close="() => $emit('toggleAssignTestDialog', false)"
    width="70%"
    center
  >
    <template
      v-if="
        enrolled_session.entry_test_paper_id &&
          enrolled_session.entry_test_paper_id > 0
      "
    >
      <template
        v-if="
          enrolled_session.entry_test_result_id &&
            enrolled_session.entry_test_result_id > 0
        "
      >
        <el-alert
          v-if="enrolled_session.score"
          title="分班考試已完成。"
          type="success"
          :closable="false"
        >
        </el-alert>
        <el-alert
          v-else
          title="學生已經完成考試,請等待試卷批改。"
          type="warning"
          :closable="false"
        >
        </el-alert>
      </template>
      <template v-else>
        <el-alert
          title="分班考試已安排,等待學生作答。"
          type="warning"
          :closable="false"
        >
        </el-alert>
      </template>
    </template>
    <div v-else>
      <el-alert title="設置分班考試。" type="warning" :closable="false">
      </el-alert>
    </div>
    <hr />
    <el-form
      v-if="sessionsTestsOnlineSATPractices && paperList"
      :model="enrollmentForm"
      :validate-on-rule-change="false"
      ref="assignTestForm"
      label-width="130px"
    >
      <el-form-item>
        <b slot="label">是否考過分班考：</b>
        <el-radio-group v-model="hasExam">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <template v-if="hasExam">
        <!-- <el-form-item>
          <b slot="label">考試類型：</b>
          <el-radio-group v-model="diagnostic_type" size="small">
            <el-radio-button label="sat">SAT</el-radio-button>
            <el-radio-button label="act">ACT</el-radio-button>
            <el-radio-button label="toefl">TOEFL</el-radio-button>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item
          :rules="commonRules.select"
          prop="entry_test_paper_id"
          required
        >
          <b slot="label">選擇學生的考卷:</b>
          <el-select
            v-if="studentTestResults.length > 0"
            v-model="selectedStudentTestResult"
            :placeholder="$t('validation.selectRequired')"
            class="w-100"
            filterable
            required
          >
            <el-option
              :value="0"
              :label="$t('validation.selectRequired')"
            ></el-option>
            <div v-for="result in studentTestResults" :key="result.id">
              <el-option
                v-if="result.exam"
                :label="result.exam.full_name"
                :value="result.id"
              >
                <span
                  v-html="
                    `${result.exam.full_name} [<b>Score: </b>${result.total_score}]`
                  "
                ></span>
              </el-option>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item class="text-right">
          <el-button type="primary" @click="setResult">
            設置為分班考結果
          </el-button>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item>
          <b slot="label">考卷類型：</b>
          <el-radio-group
            v-model="diagnostic_type"
            @change="enrollmentForm.entry_test_paper_id = null"
          >
            <el-radio-button label="satMocks">SAT</el-radio-button>
            <el-radio-button label="sat">SAT紙本</el-radio-button>
            <el-radio-button label="act">ACT</el-radio-button>
            <el-radio-button label="toefl">TOEFL</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :rules="commonRules.select"
          prop="entry_test_paper_id"
          required
        >
          <b slot="label">選擇考卷:</b>
          <el-select
            v-if="diagnostic_type === 'satMocks'"
            v-model="enrollmentForm.entry_test_paper_id"
            :placeholder="$t('validation.selectRequired')"
            filterable
            class="w-100"
          >
            <el-option
              :value="0"
              :label="$t('validation.selectRequired')"
            ></el-option>
            <el-option
              v-for="(item, index) in sessionsTestsOnlineSATPractices"
              :key="index"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-else
            v-model="enrollmentForm.entry_test_paper_id"
            :placeholder="$t('validation.selectRequired')"
            class="w-100"
            filterable
          >
            <el-option
              :value="0"
              :label="$t('validation.selectRequired')"
            ></el-option>
            <el-option
              v-for="paper in paperList[diagnostic_type]"
              :key="paper.exam_id"
              :label="'[' + paper.title + '] ' + paper.full_name"
              :value="paper.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required>
          <b slot="label">如何考試：</b>
          <div
            v-if="diagnostic_type === 'toefl' || diagnostic_type === 'satMocks'"
          >
            學生<b class="text-warning"> 在線 </b>進行考试，發送
            <b class="text-success">在線考試鏈接</b> 到學生的郵箱。
          </div>
          <div v-if="diagnostic_type === 'act' || diagnostic_type === 'sat'">
            <el-radio-group v-model="diagnostic_email" size="small">
              <el-radio label="noEmail" style="display:block">
                學生在<b class="text-warning">教室</b
                >進行考試，幫助學生創建分班考試。
              </el-radio>
              <!-- <el-radio label="emailTimer" style="display:block">
                學生在<b class="text-warning">家裡</b>進行考试，學生<b
                  class="text-success"
                  >有試卷</b
                >，發送<b class="text-success">計時器</b
                >到學生的郵箱，學生進行考试並自行批改。
              </el-radio> -->
              <el-radio label="emailAll" style="display:block">
                學生在<b class="text-warning">家裡</b>進行考试，發送<b
                  class="text-success"
                  >試卷</b
                >和<b class="text-success">計時器</b
                >到學生的郵箱，學生下載考卷進行考试並自行批改。
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item
          required
          v-if="
            diagnostic_email === 'noEmail' &&
              (diagnostic_type === 'act' || diagnostic_type === 'sat')
          "
        >
          <b slot="label">考試地點：</b>
          <el-input v-model="testLocation"></el-input>
        </el-form-item>
        <el-form-item required>
          <b slot="label">截止日期：</b>
          <el-date-picker
            v-model="testDate"
            type="date"
            placeholder="選擇日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="text-right">
          <div v-if="enrolled_session.entry_test_status === null">
            <el-button
              v-if="diagnostic_email !== 'noEmail'"
              type="primary"
              @click="sendTestEmail(true)"
            >
              {{ $t("enrollment.detail.saveButtonEmail") }}
            </el-button>
            <el-button
              v-if="diagnostic_email === 'noEmail'"
              type="primary"
              @click="sendTestEmail(false)"
            >
              {{ $t("enrollment.detail.saveButton") }}
            </el-button>
          </div>
          <div v-else>
            <el-button type="primary" @click="sendTestEmail(false)">
              {{ $t("enrollment.detail.saveButton") }}
            </el-button>
            <el-button
              v-if="diagnostic_email !== 'noEmail'"
              type="primary"
              @click="sendTestEmail(true)"
            >
              {{ $t("enrollment.detail.saveButtonEmail") }}
            </el-button>
          </div>
        </el-form-item>
      </template>
    </el-form>
  </el-dialog>
</template>

<script>
import enrollmentApi from "@/apis/enrollment";
import formMixin from "@/mixins/form";
import courseApi from "@/apis/course";

export default {
  mixins: [formMixin],
  props: {
    isAssignTestDialogVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    enrolled_session: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      sessionsTestsOnlineSATPractices: null,
      student_profile: null,
      studentTestResults: [],
      selectedStudentTestResult: null,
      testLocation: "",
      testDate: "",
      enrollmentForm: {},
      // enrolled_session: {},
      paperList: null,
      diagnostic_email: "noEmail",
      diagnostic_type: "satMocks",
      hasExam: false
    };
  },
  watch: {
    isAssignTestDialogVisible() {
      if (this.isAssignTestDialogVisible && this.$refs["assignTestForm"]) {
        this.$refs["assignTestForm"].clearValidate();
      }
    },
    // diagnostic_type() {
    //   this.enrollmentForm.entry_test_paper_id = null;
    // },
    enrolled_session() {
      console.log(1);
      this.setDiagnosticType();
    }
  },
  async created() {
    const res = await courseApi.getOnlineSATExams({
      mark: "new",
      type: "compose-practice"
    });
    this.sessionsTestsOnlineSATPractices = res.exams;
    await this.getAssignTest();
    const enrollmentRes = await enrollmentApi.fetch({ id: this.id });
    // const { student_profile, enrolled_session, course_session } = enrollmentRes;
    const { student_profile, course_session } = enrollmentRes;
    this.student_profile = student_profile;
    // this.enrolled_session = enrolled_session || {};
    this.course_session = course_session || {};

    if (this.enrolled_session.entry_test_status !== null) {
      if (this.enrolled_session.entry_test_status === 0) {
        this.diagnostic_email = "noEmail";
      } else if (this.enrolled_session.entry_test_status === 1) {
        this.diagnostic_email = "emailAll";
      } else {
        this.diagnostic_email = "emailTimer";
      }
      if (this.enrolled_session.entry_test_date) {
        this.testDate = this.enrolled_session.entry_test_date;
      }
      if (this.enrolled_session.entry_test_location) {
        this.testLocation = this.enrolled_session.entry_test_location;
      }
      const {
        know_us,
        is_student_referral,
        referrer,
        will_mandarin_help,
        payment_method,
        currency
      } = student_profile.student;
      this.enrollmentForm = {
        know_us,
        is_student_referral,
        referrer,
        will_mandarin_help,
        payment_method,
        currency,
        entry_test_paper_id: this.enrolled_session.entry_test_paper_id
      };
    }
    this.getStudentTestResults();
    this.setDiagnosticType();
  },

  methods: {
    setDiagnosticType() {
      if (
        this.enrolled_session.entry_test_type ===
          "App\\Entities\\SatMocks\\Exam" ||
        this.enrolled_session.entry_test_paper_type ===
          "App\\Entities\\SatMocks\\Exam"
      ) {
        this.diagnostic_type = "satMocks";
      } else {
        if (this.enrolled_session.exam) {
          switch (this.enrolled_session.exam.exam_category_id) {
            case 1:
              this.diagnostic_type = "toefl";
              break;
            case 2:
              this.diagnostic_type = "sat";
              break;
            case 3:
              this.diagnostic_type = "act";
              break;
          }
        }
      }
    },
    async getStudentTestResults() {
      const res = await enrollmentApi.getStudentTestResults(
        this.student_profile.basic_info.id
      );
      this.studentTestResults = res.user_exams;
    },
    async getAssignTest() {
      if (JSON.stringify(this.course_session) !== "{}") {
        const tests = await enrollmentApi.getAssignTest();
        this.paperList = tests;
      }
    },
    async sendTestEmail(isSend) {
      let sendEmail = 0;
      let exam_type = null;
      if (this.diagnostic_email === "emailTimer") {
        sendEmail = 2;
      } else if (this.diagnostic_email === "emailAll") {
        sendEmail = 1;
      } else if (this.diagnostic_email === "noEmail") {
        sendEmail = 0;
      }
      if (this.diagnostic_type === "satMocks") {
        sendEmail = 3;
        exam_type = "App\\Entities\\SatMocks\\Exam";
      } else {
        exam_type = "App\\Entities\\Exam";
      }
      if (
        (this.testLocation === "" && sendEmail === 0) ||
        this.testDate === "" ||
        this.enrollmentForm.entry_test_paper_id === null
      ) {
        this.$message({
          message: "請填寫完整資料！",
          type: "error"
        });
        return;
      }
      try {
        await enrollmentApi.updateAssignTest(this.id, {
          exam_id: this.enrollmentForm.entry_test_paper_id,
          is_mail: sendEmail,
          is_send: isSend,
          location: this.testLocation,
          exam_type: exam_type,
          date: this.testDate
        });
        this.$emit("toggleAssignTestDialog", false);
        this.$emit("reload");
        this.$message({
          message: this.$t("message.set_success"),
          type: "success"
        });
      } catch (e) {
        this.$message({
          showClose: true,
          message: this.$t("message.something_went_wrong"),
          type: "error"
        });
      }
    },
    async setResult() {
      await enrollmentApi.update({
        id: this.id,
        entry_test_result_id: this.selectedStudentTestResult
      });
      this.$message({
        message: this.$t("message.set_success"),
        type: "success"
      });
      this.$emit("toggleAssignTestDialog", false);
      this.$emit("reload");
    }
  }
};
</script>

<style lang="scss" scoped></style>
