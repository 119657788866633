<template>
  <el-dialog
    :title="$t('enrollment.detail.log')"
    :visible.sync="isLogDialogVisible"
    :before-close="() => $emit('toggleLogDialog', false)"
    center
  >
    <template slot="title">
      <h5>
        {{ $t("enrollment.detail.log") }}
        <el-tooltip
          content="只有管理員可以看到這個紀錄"
          placement="top"
          popper-class="tooltipColor"
        >
          <span><i class="fas fa-lock"/></span>
        </el-tooltip>
      </h5>
    </template>
    <el-form
      @submit.prevent.native="addContact"
      :model="logForm"
      :validate-on-rule-change="false"
      ref="logForm"
    >
      <el-form-item :rules="commonRules.input" prop="content" required>
        <el-input
          type="textarea"
          v-model="logForm.content"
          :rows="5"
          required
        ></el-input>
      </el-form-item>
      <el-form-item class="text-right">
        <el-button native-type="submit" type="primary">
          {{ $t("enrollment.detail.saveButton") }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import enrollmentApi from "@/apis/enrollment";
import formMixin from "@/mixins/form";

class LogForm {
  content = "";
}

export default {
  mixins: [formMixin],
  props: {
    isLogDialogVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      logForm: new LogForm()
    };
  },
  watch: {
    isLogDialogVisible() {
      if (this.isLogDialogVisible && this.$refs["logForm"]) {
        this.$refs["logForm"].clearValidate();
      }
    }
  },

  methods: {
    async addContact() {
      const valid = await this.validateForm(this.$refs["logForm"]);
      if (!valid) {
        return;
      }

      const form = JSON.parse(JSON.stringify(this.logForm));

      await enrollmentApi.addContact({
        id: this.id,
        ...form
      });

      await this.$emit("reload");
      this.$emit("toggleLogDialog", false);

      this.logForm = new LogForm();
    }
  }
};
</script>

<style lang="scss" scoped></style>
