<template>
  <div>
    <i class="ivy-done ivy-step el-icon-warning"></i>
    <i
      :class="{ 'ivy-done': currentStep > 1 }"
      class="ivy-step el-icon-warning"
    ></i>
    <i
      :class="{ 'ivy-done': currentStep > 2 }"
      class="ivy-step el-icon-warning"
    ></i>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["currentStep"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style lang="scss" scoped>
.ivy-step {
  color: #f56c6c;

  &.ivy-done {
    color: #42a16a;

    &:before {
      content: "\e79c";
    }
  }
}
</style>
