<template>
  <div>
    <el-tooltip
      v-if="!hasContract && !hasSignedContract"
      content="發送/上傳合約"
      placement="top"
      popper-class="tooltipWrong"
    >
      <span
        style="color: red"
        @click="
          () => {
            $emit('openContractDialog');
          }
        "
      >
        未建立 <i class="el-icon-plus warning-icon" />
      </span>
    </el-tooltip>
    <span
      v-if="hasContract && !hasSignedContract"
      style="color: orange; cursor: pointer;"
      @click="
        () => {
          $emit('openContractDialog');
        }
      "
    >
      {{ `於 ${instant.formatDate(contractCreatedAt)} 建立` }}
    </span>
    <span
      v-if="hasSignedContract"
      class="action-icon"
      @click="
        () => {
          $emit('openContractDialog');
        }
      "
    >
      {{ `於 ${instant.formatDate(contractSignedAt)} 完成` }}
    </span>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";

export default {
  computed: {
    instant() {
      return instant;
    }
  },
  props: [
    "hasContract",
    "contractCreatedAt",
    "hasSignedContract",
    "contractSignedAt"
  ]
};
</script>
