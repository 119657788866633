var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.hasPlacementTest.user_exam_id)?_c('el-tooltip',{attrs:{"effect":"dark","content":"請設置分班考試","placement":"bottom"}},[_c('b',{staticClass:"text-info"},[_vm._v("暫未設置分班考試")])]):_vm._e(),(_vm.categoryName === 'satMocks')?[(
        _vm.hasPlacementTest.user_exam_id > 0 && _vm.hasPlacementTest.score === null
      )?_c('el-tooltip',{attrs:{"effect":"dark","content":'(' +
          _vm.instant.formatDate(_vm.hasPlacementTest.entry_test_date) +
          ') 分班考試已安排，請等待學生作答。',"placement":"bottom"}},[(_vm.hasPlacementTest.exam)?_c('b',[_c('a',{staticClass:"text-warning",attrs:{"href":`https://satmocks.com/result/${_vm.hasPlacementTest.user_exam_id}?practiceType=composePractices`}},[_vm._v(" "+_vm._s(_vm.hasPlacementTest.exam.title)+" ")])]):_vm._e()]):_vm._e(),(_vm.hasPlacementTest.score >= 0 && _vm.hasPlacementTest.score !== null)?_c('el-tooltip',{attrs:{"effect":"dark","content":'考試: ' + _vm.hasPlacementTest.exam.title + ' 已經完成，請及時分班。',"placement":"bottom"}},[_c('b',[_c('a',{attrs:{"href":`https://satmocks.com/result/${_vm.hasPlacementTest.user_exam_id}?practiceType=composePractices`}},[_vm._v(" "+_vm._s(_vm.instant.formatDate(_vm.hasPlacementTest.exam.updated_at))+" ("+_vm._s(_vm.hasPlacementTest.score)+") ")])])]):_vm._e()]:(_vm.categoryName === 'toefl')?[(
        _vm.hasPlacementTest.user_exam_id > 0 && _vm.hasPlacementTest.score === null
      )?_c('el-tooltip',{attrs:{"effect":"dark","content":'(' +
          _vm.instant.formatDate(_vm.hasPlacementTest.entry_test_date) +
          ') 分班考試已安排，請等待學生作答。',"placement":"bottom"}},[_c('b',{staticClass:"text-warning"},[_vm._v(" "+_vm._s(_vm.hasPlacementTest.exam.title)+" ")])]):_vm._e(),(_vm.hasPlacementTest.score >= 0 && _vm.hasPlacementTest.score !== null)?_c('el-tooltip',{attrs:{"effect":"dark","content":'考試: ' + _vm.hasPlacementTest.exam.title + ' 已經完成，請及時分班。',"placement":"bottom"}},[_c('b',[_c('a',{staticClass:"text-warning",attrs:{"href":`https://satmocks.com/result/${_vm.hasPlacementTest.user_exam_id}?practiceType=composePractices`}},[_vm._v(" "+_vm._s(_vm.instant.formatDate(_vm.hasPlacementTest.exam.updated_at))+" ("+_vm._s(_vm.hasPlacementTest.score)+") ")])])]):_vm._e()]:[(
        _vm.hasPlacementTest.user_exam_id > 0 && _vm.hasPlacementTest.score === null
      )?_c('el-tooltip',{attrs:{"effect":"dark","content":'(' +
          _vm.instant.formatDate(_vm.hasPlacementTest.entry_test_date) +
          ') 分班考試已安排，請等待學生作答并批改。',"placement":"bottom"}},[(_vm.hasPlacementTest.exam)?_c('b',[_c('router-link',{staticClass:"text-warning",attrs:{"to":`/tests/grading/${_vm.categoryName}?keyboard=2&exam_id=${_vm.hasPlacementTest.exam_id}&user_id=${_vm.hasPlacementTest.student_id}&user_exam_id=${_vm.enrollment.entry_test_result_id}`}},[_vm._v(_vm._s(_vm.hasPlacementTest.exam.title))])],1):_vm._e()]):_vm._e(),(_vm.hasPlacementTest.score >= 0 && _vm.hasPlacementTest.score !== null)?_c('el-tooltip',{attrs:{"effect":"dark","content":'考試: ' + _vm.hasPlacementTest.exam.title + ' 已經完成，請及時分班。',"placement":"bottom"}},[_c('b',[_c('router-link',{staticClass:"action-icon",attrs:{"to":`/${_vm.categoryName}/result?user_exam_id=${_vm.enrollment.entry_test_result_id}`}},[_vm._v(" "+_vm._s(_vm.instant.formatDate(_vm.hasPlacementTest.user_exam.updated_at))+" ("+_vm._s(_vm.hasPlacementTest.score)+") ")])],1)]):_vm._e()],_c('span',{staticClass:"action-icon",on:{"click":() => _vm.$emit('toggleAssignTestDialog', true)}},[_c('i',{staticClass:"fas fa-edit action-icon"})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }