var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.hasContract && !_vm.hasSignedContract)?_c('el-tooltip',{attrs:{"content":"發送/上傳合約","placement":"top","popper-class":"tooltipWrong"}},[_c('span',{staticStyle:{"color":"red"},on:{"click":() => {
          _vm.$emit('openContractDialog');
        }}},[_vm._v(" 未建立 "),_c('i',{staticClass:"el-icon-plus warning-icon"})])]):_vm._e(),(_vm.hasContract && !_vm.hasSignedContract)?_c('span',{staticStyle:{"color":"orange","cursor":"pointer"},on:{"click":() => {
        _vm.$emit('openContractDialog');
      }}},[_vm._v(" "+_vm._s(`於 ${_vm.instant.formatDate(_vm.contractCreatedAt)} 建立`)+" ")]):_vm._e(),(_vm.hasSignedContract)?_c('span',{staticClass:"action-icon",on:{"click":() => {
        _vm.$emit('openContractDialog');
      }}},[_vm._v(" "+_vm._s(`於 ${_vm.instant.formatDate(_vm.contractSignedAt)} 完成`)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }