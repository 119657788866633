<template>
  <div>
    <el-tooltip
      v-if="!hasPlacementTest.user_exam_id"
      effect="dark"
      content="請設置分班考試"
      placement="bottom"
    >
      <b class="text-info">暫未設置分班考試</b>
    </el-tooltip>
    <template v-if="categoryName === 'satMocks'">
      <el-tooltip
        v-if="
          hasPlacementTest.user_exam_id > 0 && hasPlacementTest.score === null
        "
        effect="dark"
        :content="
          '(' +
            instant.formatDate(hasPlacementTest.entry_test_date) +
            ') 分班考試已安排，請等待學生作答。'
        "
        placement="bottom"
      >
        <b v-if="hasPlacementTest.exam">
          <a
            class="text-warning"
            :href="
              `https://satmocks.com/result/${hasPlacementTest.user_exam_id}?practiceType=composePractices`
            "
          >
            {{ hasPlacementTest.exam.title }}
          </a>
        </b>
      </el-tooltip>
      <el-tooltip
        v-if="hasPlacementTest.score >= 0 && hasPlacementTest.score !== null"
        effect="dark"
        :content="
          '考試: ' + hasPlacementTest.exam.title + ' 已經完成，請及時分班。'
        "
        placement="bottom"
      >
        <b>
          <a
            :href="
              `https://satmocks.com/result/${hasPlacementTest.user_exam_id}?practiceType=composePractices`
            "
          >
            {{ instant.formatDate(hasPlacementTest.exam.updated_at) }} ({{
              hasPlacementTest.score
            }})
          </a>
        </b>
      </el-tooltip>
    </template>
    <template v-else-if="categoryName === 'toefl'">
      <el-tooltip
        v-if="
          hasPlacementTest.user_exam_id > 0 && hasPlacementTest.score === null
        "
        effect="dark"
        :content="
          '(' +
            instant.formatDate(hasPlacementTest.entry_test_date) +
            ') 分班考試已安排，請等待學生作答。'
        "
        placement="bottom"
      >
        <b class="text-warning">
          {{ hasPlacementTest.exam.title }}
        </b>
      </el-tooltip>
      <el-tooltip
        v-if="hasPlacementTest.score >= 0 && hasPlacementTest.score !== null"
        effect="dark"
        :content="
          '考試: ' + hasPlacementTest.exam.title + ' 已經完成，請及時分班。'
        "
        placement="bottom"
      >
        <b>
          <a
            class="text-warning"
            :href="
              `https://satmocks.com/result/${hasPlacementTest.user_exam_id}?practiceType=composePractices`
            "
          >
            {{ instant.formatDate(hasPlacementTest.exam.updated_at) }} ({{
              hasPlacementTest.score
            }})
          </a>
        </b>
      </el-tooltip>
    </template>
    <template v-else>
      <el-tooltip
        v-if="
          hasPlacementTest.user_exam_id > 0 && hasPlacementTest.score === null
        "
        effect="dark"
        :content="
          '(' +
            instant.formatDate(hasPlacementTest.entry_test_date) +
            ') 分班考試已安排，請等待學生作答并批改。'
        "
        placement="bottom"
      >
        <b v-if="hasPlacementTest.exam">
          <router-link
            class="text-warning"
            :to="
              `/tests/grading/${categoryName}?keyboard=2&exam_id=${hasPlacementTest.exam_id}&user_id=${hasPlacementTest.student_id}&user_exam_id=${enrollment.entry_test_result_id}`
            "
            >{{ hasPlacementTest.exam.title }}</router-link
          >
        </b>
      </el-tooltip>
      <el-tooltip
        v-if="hasPlacementTest.score >= 0 && hasPlacementTest.score !== null"
        effect="dark"
        :content="
          '考試: ' + hasPlacementTest.exam.title + ' 已經完成，請及時分班。'
        "
        placement="bottom"
      >
        <b>
          <router-link
            class="action-icon"
            :to="
              `/${categoryName}/result?user_exam_id=${enrollment.entry_test_result_id}`
            "
          >
            {{ instant.formatDate(hasPlacementTest.user_exam.updated_at) }}
            ({{ hasPlacementTest.score }})
          </router-link>
        </b>
      </el-tooltip>
    </template>
    <span
      class="action-icon"
      @click="() => $emit('toggleAssignTestDialog', true)"
    >
      <i class="fas fa-edit action-icon" />
    </span>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";

export default {
  props: ["enrollment", "hasPlacementTest"],
  computed: {
    instant() {
      return instant;
    },
    categoryName() {
      if (
        this.hasPlacementTest.entry_test_type ===
        "App\\Entities\\SatMocks\\Exam"
      ) {
        return "satMocks";
      } else {
        if (this.hasPlacementTest.exam) {
          switch (this.hasPlacementTest.exam.exam_category_id) {
            case 1:
              return "toefl";
            case 2:
              return "sat";
            case 3:
              return "act";
          }
        }
      }
      return null;
    }
  }
};
</script>
